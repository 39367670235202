body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

.ant-input:focus,
.ant-input-focused {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ant-breadcrumb a,
.ant-breadcrumb > span:last-child a {
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wapperContent {
  display: inline-block;
  width: 100%;
  overflow: auto;
}
.marginTop {
  margin-top: 90px;
}

.greencolor {
  color: #6cbd5a;
}

.bluecolor {
  color: #024d79;
}

.graycolor {
  color: #737373;
}
.grayColor {
  color: #828282;
}
.fontSize11 {
  font-size: 11px;
}
.font14 {
  font-size: 14px;
}

.fontweight {
  font-weight: bold;
}
.shdowBox {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
}
.pointer {
  cursor: pointer;
}
.pointer:active {
  opacity: 0.8;
}
.padding15 {
  padding: 15px;
}

/*button style */

.btnStyle {
  background: #55b33e;
  color: var(--whiteColoer);
  border: none;
  border-radius: 15px;
  padding: 10px 15px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 14px;
  max-width: fit-content;
  border-radius: 25.5px;
  padding: 10px 26px;
  margin: 0 auto;
  display: block;
}

.boxTotal {
  padding: 5px;
  margin: 10px 0;
  background: #eeeeee;
  display: block;
  width: 250px;
  border-radius: 5px;
  color: black;
}

.link {
  cursor: pointer;
  display: inline-block;
}

.link:hover {
  opacity: 0.6;
}

.paddingP {
  padding: 5px;
}

.colorWhite {
  color: white;
}
.btnGreen {
  display: block;
  margin: auto;
  padding: 11px;
  border-radius: 8px;
  min-width: 100px;
  background: #56b247;
  color: white;
}

button {
  cursor: pointer;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: #888;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #0a4d7b;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.pointer {
  cursor: pointer;
}
.container {
  max-width: 100%;
  margin: 0 auto;
  border-right: 1px solid #d0d0d0;
  border-left: 1px solid #d0d0d0;
}

.menu-modal button.ant-modal-close {
  position: static;
  margin: 0 auto;
  width: 49px;
  display: block;
}

.content {
  height: calc(100vh - 95px);
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.bottom {
  position: absolute;
  bottom: 0px;
}

.boxFedback {
  height: 35px;
  left: 0px;
  top: -28.34px;
  background: #72c6d1 !important;
  border-radius: 25px;
  border-radius: 25px !important;
  width: 144.87px !important;
  position: relative;
}
.boxFedbackM {
  height: 35px;
  left: 114px;
  top: -6.34px;
  background: #72c6d1 !important;
  border-radius: 25px;
  border-radius: 25px !important;
  width: 144.87px !important;
  position: relative;
}
.right0 {
  right: 0;
}

.scroll {
  overflow-y: scroll;
}

.scroll::-webkit-scrollbar {
  width: 4px !important;
}

.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #5edc8933;
  border-radius: 10px;
}

/* colors */
.white {
  color: #fff !important;
}

.black {
  color: #333333 !important;
}

.yellow {
  color: #ffd147 !important;
}

.yellow2 {
  color: #ffd147 !important;
}

.black {
  color: #282d32 !important;
}

.gray {
  color: #3b4149 !important;
}

.gray2 {
  color: #90a0b7 !important;
}

.grayText {
  color: #90a0b7 !important;
}

.blue {
  color: #7ad9f5 !important;
}

.blueT {
  color: #2a689457 !important;
}

.blue2 {
  color: #1890ff !important;
}

.red {
  color: #ff8585 !important;
}

.red2 {
  color: #cc4c4c !important;
}

.green {
  color: #5edc89 !important;
}

.pointer {
  cursor: pointer !important;
}

.cursor-grab {
  cursor: grab !important;
}

/* background */
.transparent {
  background-color: transparent !important;
}
.b-pirples {
  background: #e1c7b9 !important;
}

.b-white {
  background: #fff !important;
}

.b-gray {
  background: #3b4149 !important;
}

.b-grayM {
  background: #494f57 !important;
}

.b-grayM2 {
  background: #818286 !important;
}

.b-yellow {
  background: #ffd147 !important;
}

.b-yellow2 {
  background: #ffd14717 !important;
}

.b-blue {
  background: #7ad9f5 !important;
}

.b-blue2 {
  background-color: #d9f0ff !important;
}

.b-blueT {
  background: #2a689457 !important;
}
.b-pirple {
  background: #4250b2 !important;
}

.b-red {
  background: #ff8585 !important;
}

.b-off-white {
  background: #faf1ed !important;
}

.b-red2 {
  background: #cc4c4c !important;
}

.b-green {
  background: #5edc89 !important;
}

/* border */
.no-border {
  border: none !important;
}

.br-gray {
  border-color: #3b4149 !important;
}

.br-grayM {
  border-color: #928c8c !important;
}

.br-yellow2 {
  border-color: #ffd14717 !important;
}

.br-blue {
  border-color: #7ad9f5 !important;
}

.br-blue2 {
  border-color: #d9f0ff !important;
}

.br-blueT {
  border-color: #2a689457 !important;
}

.br-black {
  border-color: #00000057 !important;
}

.br-red {
  border-color: #ff8585 !important;
}

.br-red2 {
  border-color: #cc4c4c !important;
}

.br-green {
  border-color: #5edc89 !important;
  span {
    color: #5edc89 !important;
  }
}

.radius {
  border-radius: 6px !important;
}

.circle {
  border-radius: 50px !important;
}

.dash {
  border: 1px dashed;
}

.br {
  border: 1px solid;
}

.br-left {
  border-left: 1px solid;
}

.br-right {
  border-right: 1px solid;
}

.br-bottem {
  border-bottom: 1px solid;
}

.br-top {
  border-top: 1px solid;
}

/* width */
.full {
  width: 100% !important;
}

.full-height {
  height: 100vh !important;
}

.col-6 {
  width: 50% !important;
}

/* image */

.imageFull {
  max-height: 300px;
  object-fit: cover;
  width: 100%;
}

.imageHalf {
  max-height: 150px;
  width: 100%;
  object-fit: cover;
}

/* border */
.no-border {
  border: none !important;
}

/* float */
.fLeft {
  float: left !important;
}

.fRight {
  float: right !important;
}

/* button */

.buttonSquere {
  width: 50px;
}

.auto {
  width: auto;
}

.ant-select {
  margin-top: 0px !important;
}
/* padding */

.no-padding {
  padding: 0px !important;
}

.padding5 {
  padding: 5px !important;
}

.padding10 {
  padding: 10px !important;
}

.padding15 {
  padding: 15px !important;
}

.paddingVer20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.paddingVer30 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.padding40 {
  padding: 40px !important;
}

.padding60 {
  padding: 60px !important;
}

.paddingVer40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}
.paddingVer10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.paddingHor10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.paddingHor20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding20 {
  padding: 20px !important;
}

.paddingRight20 {
  margin-right: 30px;
}

.paddingTop5 {
  padding-top: 5px !important;
}

.paddingTop60 {
  padding-top: 60px !important;
}
.paddingRight30 {
  padding-right: 30px !important;
}

/* margin */
.margin5 {
  margin: 5px;
}

.margin10 {
  margin: 10px;
}

.margin7 {
  margin: 7px;
}

.marginTop10 {
  margin-top: 10px !important;
}

.marginBottom10 {
  margin-bottom: 10px !important;
}

.marginBottom20 {
  margin-bottom: 20px !important;
}

.marginBottom40 {
  margin-bottom: 40px !important;
}

.marginBottom50 {
  margin-bottom: 50px !important;
}

.marginBottom100 {
  margin-bottom: 100px !important;
}

.marginBottom150 {
  margin-bottom: 150px !important;
}

.marginTop20 {
  margin-top: 20px !important;
}

.marginTop40 {
  margin-top: 40px !important;
}

.marginTop50 {
  margin-top: 50px !important;
}

.marginVer10 {
  margin-left: 10px;
  margin-right: 10px;
}

.marginVer20 {
  margin-left: 20px;
  margin-right: 20px;
}

.marginHor20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.marginHor50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.marginHor10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.margin20 {
  margin: 20px;
}

.marginLeft20 {
  margin-left: 20px;
}

.marginLeft5 {
  margin-left: 5px;
}
.marginLeft10 {
  margin-left: 10px;
}

.marginRight5 {
  margin-right: 5px !important;
}

.marginRight10 {
  margin-right: 10px;
}

.marginRight10 {
  margin-right: 10px;
}

.marginRight20 {
  margin-right: 20px !important;
}
.marginRight40 {
  margin-right: 40px !important;
}

.marginRight100 {
  margin-right: 100px !important;
}

.marginRight30 {
  margin-right: 30px !important;
}

.marginCenter {
  margin: 0 auto !important;
}

.no-margin {
  margin: 0 !important;
}

/* font */
.bold {
  font-weight: bold;
}
.b6 {
  font-weight: 600;
}

.fw400 {
  font-weight: 400 !important;
}

.f100 {
  font-size: 100px;
}

.f90 {
  font-size: 90px;
}

.f60 {
  font-size: 60px;
}

.f40 {
  font-size: 40px;
}

.f12 {
  font-size: 12px;
}

.f35 {
  font-size: 35px;
}

.f55 {
  font-size: 55px;
}

.f25 {
  font-size: 25px;
  line-height: normal;
}

.f27 {
  font-size: 27px;
  line-height: normal;
}

.f21 {
  font-size: 21px !important;
}

.f16 {
  font-size: 16px;
}
.f15 {
  font-size: 15px;
}

.f45 {
  font-size: 45px;
}

.f35 {
  font-size: 35px;
}
.f14 {
  font-size: 14px;
}

.f11 {
  font-size: 11px;
}

.f1 {
  font-size: 25px;
}

.f2 {
  font-size: 20px;
}

.f3 {
  font-size: 18px;
}

.f4 {
  font-size: 14px;
}

.f5 {
  font-size: 12px;
}

.f6 {
  font-size: 10px;
}

.link {
  color: #7ad9f5;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.flexVerCenter {
  align-items: center;
}
.flexHorCenter {
  justify-content: center;
}
.spaceAround {
  justify-content: space-around;
}

/* align */
.textCenter {
  text-align: center !important;
}

.textLeft {
  text-align: left !important;
}

.textRight {
  text-align: right !important;
}

/*transfprm */
.capitalize {
  text-transform: capitalize;
}
/* display */
.show {
  display: block;
}

.contents {
  display: contents;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex !important;
}

.flex1 {
  flex: 1;
}

.flex1 {
  flex: 1;
}

.lineHeight0 {
  line-height: 0;
}

.justify-center {
  justify-content: center;
}

.flow-root {
  display: flow-root !important;
}
.grid {
  display: grid !important;
}

.grid5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.grid3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.gap40 {
  grid-gap: 40px;
}
.gap20 {
  grid-gap: 20px;
}

.gap30 {
  grid-gap: 30px;
}

.hide {
  display: none !important;
}

.box {
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.rtl {
  direction: rtl;
}
.ant-collapse-item {
  direction: rtl;
}

/* modal */
.ant-row {
  display: inline-block !important;
  margin-right: 95px !important;
}

.textFilter {
  font-family: Assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-align: right;
  position: absolute;
  color: #7ad9f5;
}

.textHeaderCard {
  font-family: Assistant !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 17px !important;
  line-height: 22px !important;
  text-align: right !important;
  color: #7ad9f5 !important;
  width: 56%;
  padding-right: 27px;
}
.textCard {
  width: 128px;
  height: 30px;
  margin-top: 20px;
  margin-right: 9.37px;
  font-family: Assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  text-align: right;
  color: #7ad9f5;
}

.addressStyle {
  font-family: Assistant;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  text-align: left;

  color: #7ad9f5;
}

.ant-modal-body {
  display: flow-root;
}
.ant-input-number-handler-wrap {
  display: none !important;
}
.ant-input-number {
  width: 33.84px !important;
  height: 33px !important;
  border: 1px solid #b7c3b7 !important;
  box-sizing: border-box;
}
.ant-input-number-input {
  color: #ffd147;
  font-family: Assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 17px !important;
  line-height: 22px;
  text-align: right;
  padding: 0.2px !important;
}
.ant-col-4 {
  width: 3.666% !important;
  left: 35px;
}
.ant-slider {
  width: 87px;
}
.ant-slider:hover .ant-slider-track {
  background-color: #ffd147 !important;
}
.ant-slider-track {
  background-color: #ffd147 !important;
  border: solid 2px #ffd147 !important;
}
.ant-slider-handle {
  background-color: #ffd147 !important;
  border: solid 2px #ffd147 !important;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgb(253, 203, 96) !important;
}

/* fix tabs */

.ant-tabs-tabpane.ant-tabs-tabpane-active {
  right: 0;
  /* position: absolute; */
  bottom: 0px;
}

.conclusion {
  direction: rtl;
}

.ant-menu {
  background: transparent !important;
  border: none !important;
}

.img-about {
  right: -820px;
  top: -45px;
  width: 971px;
  height: 700px;
  object-fit: contain;
}

.qa .ant-collapse-item {
  margin-top: 29px;
}

.qa .ant-collapse-header {
  background: transparent !important;
  color: white !important;
}

.qa .ant-collapse-item {
  background: transparent !important;
  border: 5px solid #faf1ed !important;
  box-sizing: border-box;
  border-radius: 13px;
}

.max-screen {
  max-width: 1248px !important;
}

input.ant-input {
  border-radius: 5px;
  padding: 5px;
  width: 117px;
}

textarea.ant-input {
  border-radius: 20px;
}

.ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content {
  position: relative !important;
}

.ant-tabs-tabpane.ant-tabs-tabpane-inactive {
  display: none;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active {
  display: contents !important;
  right: 0 !important;
  position: absolute !important;
  bottom: 0px !important;
  height: 200px !important;
  width: 100% !important;
}

.ant-tabs-nav.ant-tabs-nav-animated {
  display: flex;
  justify-content: center;
}

.clear {
  clear: both;
}

.add-fix {
  bottom: 10px;
  right: 10px;
  position: fixed;
  width: 87px;
}
.ant-carousel {
  width: 100% !important;
}
.ant-carousel.slick-slide {
  display: inline-block !important;
}
.familyFt {
  font-family: FtJimboryRounded;
  font-size: 92px !important;
  line-height: 90px !important;
  font-weight: bold;
}
