.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background-color: #3e444c;
}
.ant-table {
  background: transparent;
}

th {
  text-transform: capitalize;
}

main.ant-layout-content {
  height: calc(100vh - 54px);
  overflow: auto;
}

.ant-upload-list-item-card-actions-btn {
  opacity: 1 !important;
}

.ant-upload-list-item-name {
  display: contents !important;
}

#purchase_hear_about {
  padding: 15px 10px;
}

.ScanStyled {
  .ant-input[disabled]:hover,
  .ant-input[disabled]:focus,
  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector:hover,
  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector:focus,
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector:hover,
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector:focus,
  .ant-select:hover,
  .ant-select:focus,
  .ant-select-multiple .ant-select-selector:hover,
  .ant-select-multiple .ant-select-selector:focus,
  .ant-input:hover,
  .ant-input:focus,
  .ant-select-multiple .ant-select-selection-item:hover,
  .ant-select-multiple .ant-select-selection-item:focus {
    border: 1px solid #8a8e91 !important;
  }
}

.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  display: none;
}

h2.ant-typography,
.ant-typography h2 {
  color: white;
}

.ant-table thead > tr > th {
  background: #808186;
  border-bottom-color: #64676d;
  color: white;
  a {
    color: white;
  }
}
.ant-table tbody > tr > td {
  border-bottom-color: #64676d;
  color: white;
  a {
    color: white;
  }
}

.recharts-default-tooltip {
  background: #3b4149 !important;
  color: white;
  border-radius: 6px;
  padding: 10px !important;
}

.logout div {
  background: #3e444c !important;
  color: #f7ffff;
  margin: 0px;
  border-radius: 6px;
  width: 100%;
}

.logout {
  background: transparent !important;
  border: 1px solid #fff;
  border-radius: 7px;
}

.flexVerCenter .ant-select-selection-search-input {
  border-radius: 6px;
  border-color: #818286;
}

.flexVerCenter button {
  height: 32px;
  display: block;
}

.actions_table .ant-input-number-input {
  font-weight: 100;
  padding: 20px;
  margin: 0px;
  text-align: center;
}

img.action-icon {
  margin: 0px;
  cursor: pointer;
}

.table_action .ant-divider {
  height: 22px;
  margin: 0px 16px;
}

.ant-layout-header {
  height: 54px;
  border-bottom: 1px solid #23282c;
}

.ant-layout-sider {
  height: 100%;
}

.ant-select-selection-search-input {
  border-radius: 6px;
  border-color: #818286;
}

.ant-layout-sider-children {
  height: calc(100vh - 54px);
}

.main-header {
  background: rgb(59, 65, 73);
  padding-left: 30px;
  display: flex;
  align-items: center;
  * {
    color: white;
  }
}

.ant-table tbody > tr.ant-table-row:hover > td {
  background: #373c42;
}
.ant-table tbody > tr.ant-table-row:hover > td {
  background: #373c42;
}

.ant-layout-sider {
  background-color: transparent;
}

button.gray {
  background: rgb(59, 65, 73);
  border-color: white;
  color: white !important;
}

.ant-input[disabled],
.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select,
.ant-select-multiple .ant-select-selector,
.ant-input,
.ant-select-multiple .ant-select-selection-item {
  background: #282d32;
  border-color: #818286;
  color: white;
  &:hover,
  &:focus {
    border-color: #4dbd74;
  }
  span {
    color: white;
  }
}
.ant-input[disabled],
.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  opacity: 0.7;
  border-color: #818286 !important;
}

.ant-radio-wrapper {
  color: white;
}

.action-icon {
  margin-right: 5px;
  margin-left: 5px;
}

.ant-select-auto-complete {
  transition: width 200ms cubic-bezier(0, 0.78, 0.58, 1);
}

.ant-input-affix-wrapper {
  background: #282d32;
  input {
    width: 100%;
  }

  input.ant-input {
    height: auto;
  }
}

.ant-radio-inner::after {
  background: #fe8282;
  width: 90%;
  height: 91%;
  top: 1px;
  left: 1px;
}

.ant-picker.ant-picker-range.show.padding20.full {
  background: #282d32;
  border-radius: 5px;
}

svg {
  color: white;
}

.ant-picker-range input {
  color: #fff;
}

.ant-table-expanded-row td,
.ant-table-expanded-row td:hover {
  background: #282d32 !important;
}

.ant-form-item-label label {
  font-size: 21px;
  color: #fff;
}

.ant-form-item-has-error input {
  background: #282d32 !important;
}

.are_you_sure .ant-modal-content {
  background: #363c43;
  color: #fff;
}

.are_you_sure button {
  margin: 20px;
  padding: 0px 20px;
}

td:last-child {
  // display: flex;
  // justify-content: flex-end;
  // align-items: center;
}

td:last-child img {
  height: 20px;
}
li.ant-pagination-prev {
  margin: 0px;
}

ul.ant-pagination {
  border-top-left-radius: 20px !important;
}

li.ant-pagination-prev .ant-pagination-item-link {
  border-top-left-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
}

.ant-pagination-item-active {
  margin: 0px;
}

.ant-pagination-next .ant-pagination-item-link {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

li.ant-pagination-item {
  margin: 0px;
}

span.ant-modal-close-x {
  display: none;
}

.ant-input-number-input {
  text-align: center;
  color: #fff !important;
}

.ant-select-selector {
  background: transparent !important;
  border-radius: 6px !important;
  text-align: left;
  text-transform: capitalize;
}

.ant-upload-list-item-name-icon-count-1 {
  color: #4caf50 !important;
}

.ant-upload-list .ant-tooltip-content {
  display: none;
}

.ant-table-wrapper {
  // height: 663px;
  overflow-y: auto;
}

/* width */
.ant-table-wrapper::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.ant-table-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.ant-table-wrapper::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.ant-table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.permissions .ant-table-wrapper {
  overflow: hidden;
}

tr:nth-child(even) {
  background: #80818629;
}

.ant-picker.ant-picker-range {
  background: #282d32;
  border-radius: 6px;
  border-color: #818286;
}

.divider {
  border-color: #928c8c;
}

.ant-popover-inner {
  background: #2f353a;
  color: #fff !important;
}

.ant-popover-inner {
  background: #2f353a;
  color: #fff !important;
}

.ant-popover-title {
  color: #fff;
  font-size: 18px;
  border-bottom: none;
}

.ant-popover-inner-content {
  color: #fff;
  font-size: 16px;
}

.ant-popover-arrow {
  display: none;
}

.ant-popover-inner-content {
  color: #fff;
  font-size: 16px;
}

.ant-popover-arrow {
  display: none;
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}

.ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active,
.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: transparent;
}

.ant-btn,
.ant-btn-primary {
  border-radius: 7px;
}

.ant-btn-primary:not([disabled]):hover,
.ant-btn-primary:not([disabled]):focus {
  background: white !important;
  color: #3b4149 !important;
  border-color: transparent !important;
}

button.ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
  background: transparent;
  border-radius: 5px;
  width: 20px;
  height: 20px;
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  border-radius: 5px;
  width: 20px;
  height: 20px;
}

.table2 td {
  border-bottom: 1px solid #8081865e !important;
}

.logo-company {
  max-height: 32px;
  max-width: 100px;
}

input.ant-input-number-input {
  font-weight: 100;
}

.ant-table-row-expand-icon::before {
  left: 3px;
  right: 3px;
  top: 8px;
}

.ant-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 8px;
}

.location-search-input {
  border: 1px solid #818286;
  background: #282d32;
  height: 33px;
  border-radius: 6px;
}

.ant-table-row-expand-icon::after,
.ant-table-row-expand-icon::before,
.ant-table-row-expand-icon.ant-table-row-expand-icon-expanded,
button.ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
  color: #7ad9f5 !important;
  border-color: #7ad9f5 !important;
}

.ant-table-row-expand-icon.ant-table-row-expand-icon-expanded,
button.ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
  background-color: transparent !important;
}

ul.ant-pagination {
  li.ant-pagination-item {
    background: transparent;
    border: 1px solid #727579 !important;
    border-radius: 0;
  }
  li.ant-pagination-item-active {
    a {
      color: #080809;
    }
  }
}

.ant-select-dropdown [aria-selected="false"]:not(:last-child) {
  background: #3b4149 !important;
  color: #fff !important;
  margin: 0px 7px !important;
  padding-top: 0;
  padding-bottom: 0;
  .ant-select-item-option-content {
    border-bottom: 1px solid #818286 !important;
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }
}

.ant-select-item-group {
  color: #bebebe;
  font-size: 13px;
}

// .rc-virtual-list-scrollbar-thumb , .rc-virtual-list-holder {
//   width: 100%;
//   height: 128px;
//   top: 0px;
//   left: 0px;
//   position: absolute;
//   background: rgba(0, 0, 0, 0.5);
//   border-radius: 99px;
//   cursor: pointer;
//   user-select: none;
// }

.ant-select-dropdown {
  padding: 0 !important;
  border: 1px solid #818286;
  border-radius: 10px !important;
  background: #3b4149 !important;
}

.ant-select-dropdown [aria-selected="false"]:last-child {
  color: #fff;
  margin: 0px 7px;
  background: #3b4149 !important;
}
.ant-empty-description {
  color: white;
}

.are_you_sure .type {
  width: 50%;
  text-align: center;
}

.are_you_sure .type svg {
  font-size: 55px;
  cursor: pointer;
}

.plus_icon svg {
  width: 25px !important;
  margin-top: -3px;
}

.plus_icon {
  height: 25px;
}

.br-grayM {
  .ant-btn-loading-icon {
    svg {
      fill: #363c42;
    }
  }
}

.ant-table-row-expand-icon::after,
.ant-table-row-expand-icon::before,
.ant-table-row-expand-icon.ant-table-row-expand-icon-expanded,
button.ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
  color: white !important;
  border-color: white !important;
}

.ant-form-item-label > label::after {
  position: static !important;
  display: inline-block !important;
  margin-right: 4px !important;
  color: #ff4d4f !important;
  font-size: 14px !important;
  font-family: SimSun, sans-serif !important;
  line-height: 1 !important;

  content: "*" !important;
}

.not-require .ant-form-item-label > label::after {
  content: "" !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

tspan {
  fill: white;
}
.recharts-default-legend {
  display: none;
}

.download-qr-code {
  .ant-btn {
    color: white !important;
    background: transparent !important;
  }
  .ant-btn-primary {
    color: #5edc89 !important;
    background: transparent !important;
    border-color: #5edc89 !important;
    &:not([disabled]):focus,
    &:not([disabled]):hover {
      background: #5edc89 !important;
      color: #363c43 !important;
    }
  }
}

.ant-tooltip-inner {
  border: 1px solid white;
  border-radius: 6px;
  z-index: 1;
  position: relative;
}
.ant-tooltip-arrow {
  z-index: 17;
  top: -2px !important;
  .ant-tooltip-arrow-content {
    border: 1px solid white;
    width: 14px;
    height: 14px;
  }
}

.wiser-message {
  &.category-message {
    .ant-message-notice-content {
      border: 1px solid #ffd147;
      color: #ffd147;
      background: #3e444b;
      border-radius: 7px;
    }
    svg {
      color: #ffd147;
    }
  }

  &.alerts-message {
    .ant-message-notice-content {
      border: 1px solid #ce3b41;
      color: #ce3b41;
      background: #3e444b;
      border-radius: 7px;
    }
    svg {
      color: #ce3b41;
    }
  }
  &.distributor-message {
    .ant-message-notice-content {
      border: 1px solid #ffd147;
      color: #ffd147;
      background: #3e444b;
      border-radius: 7px;
    }
    svg {
      color: #ffd147;
    }
  }

  &.landing-page-message {
    .ant-message-notice-content {
      border: 1px solid #5edc89;
      color: #5edc89;
      background: #3e444b;
      border-radius: 7px;
    }
    svg {
      color: #5edc89;
    }
  }

  &.scan-message {
    .ant-message-notice-content {
      border: 1px solid #5aa2ba;
      color: #5aa2ba;
      background: #3e444b;
      border-radius: 7px;
    }
    svg {
      color: #5aa2ba;
    }
  }

  &.subscriber-message {
    .ant-message-notice-content {
      border: 1px solid #e67a7b;
      color: #e67a7b;
      background: #3e444b;
      border-radius: 7px;
    }
    svg {
      color: #e67a7b;
    }
  }
}

.ant-pagination-item-link {
  background: #3b4148 !important;
  border-color: #7f8085 !important;
}

ul.ant-pagination li.ant-pagination-item-active a {
  background: #7f8085;
  color: white;
}

.previous-button {
  display: flex;
  border: 1px solid #7f8086;
  border-radius: 5px 0 0 5px;
  height: 32px;
  color: white !important;
  padding: 0 11px;
  align-items: center;
}

.next-button {
  display: flex;
  border: 1px solid #7f8086;
  border-radius: 0 5px 5px 0;
  height: 32px;
  color: white !important;
  padding: 0 11px;
  align-items: center;
}

.ant-pagination-disabled {
  .next-button,
  .previous-button {
    opacity: 0.5;
  }
}

.gray.ant-btn:hover,
.gray.ant-btn:focus,
.gray.ant-btn:active {
  &:hover {
    background-color: transparent;
    border-color: white;
  }
}
.br-green {
  svg {
    fill: #5ddc89;
  }
}

.br-green {
  svg {
    fill: #5ddc89;
  }
}

.ant-btn-primary[disabled].br-yellow,
.br-yellow {
  border: 1px solid #ffd147;
  background-color: #3b4149 !important;
  border-color: #ffd147 !important;
  color: #ffd147 !important;

  &.ant-btn-primary:not([disabled]):hover,
  &.ant-btn-primary:not([disabled]):focus {
    border: 1px solid #ffd147;
    background-color: #3b4149 !important;
    border-color: #ffd147 !important;
    color: #ffd147 !important;
  }
  svg {
    fill: #ffd147;
  }
}

.ant-btn-primary[disabled].br-red,
.br-red {
  border: 1px solid #ff8585 !important;
  background-color: #3b4149 !important;
  border-color: #ff8585 !important;
  color: #ff8585 !important;

  &.ant-btn-primary:not([disabled]):hover,
  &.ant-btn-primary:not([disabled]):focus {
    border: 1px solid #ff8585;
    background-color: #3b4149 !important;
    border-color: #ff8585 !important;
    color: #ff8585 !important;
  }
  svg {
    fill: #ff8585;
  }
}

.ant-popover.ant-popover-placement-bottom {
  .ant-picker-input {
    input {
      font-size: 11px;
    }
  }
}
